<template>
  <v-main>

    <v-container
        id="users-container"
        fluid
        tag="section"
    >

      <base-material-card
          icon="mdi-bank"
          class="px-5 py-5"
      >

        <v-card-text v-if="invoice">

          <div>
            <v-alert
                type="error"
                outlined
                v-for="(error, i) in errors"
                :key="i"
                dense
            >
              {{ error }}
            </v-alert>
            <v-alert
                type="success"
                outlined
                v-for="(message, i) in messages"
                :key="i"
                dense
                dismissible
            >
              {{ message }}
            </v-alert>
          </div>

          <v-row
              justify="space-between"
          >
            <v-col
                sm="4"
            >
              <v-card outlined>

                <v-list-item two-line>
                  <v-list-item-content>

                    <v-list-item-title class="text-h5 mb-4">
                      Bill To:
                    </v-list-item-title>
                    <v-card-text>

                      {{ invoice.user.names }} <br>
                      <span v-if="invoice.user.address">
                        {{ invoice.user.address }}
                        <span v-if="invoice.user.addressLine2">{{ invoice.user.addressLine2 }}<br></span>
                        {{ invoice.user.city }} {{ invoice.user.state }}, {{ invoice.user.postalCode }} <br>
                      </span><br>

                      {{ invoice.user.email }}<br>
                      {{ invoice.user.phoneNumber }}
                    </v-card-text>

                  </v-list-item-content>
                </v-list-item>

              </v-card>
            </v-col>
            <v-col
                cols="12"
                sm="4"
            >

              <v-card outlined>


                  <v-list-item two-line>
                    <v-list-item-content>

                      <v-container>
                        <v-row>
                          <v-col
                              sm="6"
                          >
                            Invoice #:
                          </v-col>
                          <v-col
                              sm="6"
                          >
                            {{ invoice.id }}
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col
                              sm="6"
                          >
                            Invoice Date:
                          </v-col>
                          <v-col
                              sm="6"
                          >
                            {{ invoice.invoiceDate | formatDate }}
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col
                              sm="6"
                          >
                            Due Date:
                          </v-col>
                          <v-col
                              sm="6"
                          >
                            {{ invoice.dueDate | formatDate }}
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col
                              sm="6"
                          >
                            Status:
                          </v-col>
                          <v-col
                              sm="6"
                          >
                            {{ invoiceStatusMap[invoice.status] }}
                          </v-col>
                        </v-row>


                      </v-container>

                    </v-list-item-content>
                  </v-list-item>

              </v-card>

            </v-col>
          </v-row>


          <v-row>
            <v-col
                sm="12"
            >

              <v-simple-table>
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th class="text-subtitle-2">Description</th>
                    <th class="text-subtitle-2 text-right">Price</th>
                    <th class="text-subtitle-2 text-right">Qty</th>
                    <th class="text-subtitle-2 text-right">Total</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr
                      v-for="item in invoice.items"
                      :key="item.id"
                  >
                    <td class="item-description">
                      {{ item.description }}
                    </td>
                    <td class="item-price text-right">
                      {{ item.price | formatCurrency }}
                    </td>
                    <td class="item-qty text-right">
                      {{ item.quantity }}
                    </td>
                    <td class="item-amount text-right">
                      {{ item.amount | formatCurrency }}
                    </td>
                  </tr>

                  <tr
                      v-for="item in invoice.discounts"
                      :key="item.id"
                  >
                    <td class="item-description">
                      Discount: {{ item.description }}
                    </td>
                    <td class="item-price text-right">
                      -{{ item.value | formatCurrency }}
                    </td>
                    <td class="item-qty text-right">
                      1
                    </td>
                    <td class="item-amount text-right">
                      -{{ item.value | formatCurrency }}
                    </td>
                  </tr>


                  <tr
                      v-for="item in invoice.payments"
                      :key="item.id"
                  >
                    <td>
                      Payment on {{ item.date | formatDate }} method: {{ item.method }} ref #: {{ item.reference }}
                      <span v-if="item.status != 1">({{ paymentStatusMap[item.status] }})</span>
                    </td>
                    <td class="text-right">
                      -{{ item.amount | formatCurrency }}
                    </td>
                    <td class="text-right">
                      1
                    </td>
                    <td class="text-right">
                      <span v-if="item.status == 1">
                        -{{ item.amount | formatCurrency }}
                      </span>
                    </td>
                  </tr>

                  <tr>
                    <td colspan="3" class="font-weight-bold">
                      Balance
                    </td>
                    <td class="text-right font-weight-bold">
                      {{ invoice.balance | formatCurrency }}
                    </td>
                  </tr>

                  </tbody>
                </template>
              </v-simple-table>


            </v-col>
          </v-row>


          <v-row>
            <v-col
                sm="3"
                v-if="invoice.comments"
            >
              Notes:
            </v-col>
            <v-col
                sm="9"
            >
              {{ invoice.comments }}
            </v-col>
          </v-row>

          <v-row v-if="showMakePayment">
            <v-col>
              <router-link :to="{name: 'add_edit_payment', params: {invoiceId: invoice.id}}">
                <v-btn
                    color="primary"
                    type="submit"
                    id="submitPayment"
                >
                  Make Payment
                </v-btn>
              </router-link>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              Please note that payments will not be applied to the invoice until they are reviewed by an administrator.
            </v-col>
          </v-row>




        </v-card-text>


      </base-material-card>


    </v-container>

  </v-main>
</template>

<script>
import api from '@/services/api'
import constants from '@/utils/constants'
import validation from "@/utils/validation";
import {mapGetters} from "vuex";

export default {
  name: 'CoreViewInvoiceView',

  created() {
    this.$gtag.event('view_invoice')

    this.userId = this.getUserId
    this.invoiceId = this.$route.params.id
    this.fetchInvoice()
  },
  computed: {
    ...mapGetters({
      getUserId: 'getUserId'
    }),
    showMakePayment() {
      if (this.invoice.status === constants.INVOICE_STATUS_PAID || this.invoice.balance === 0) {
        return false
      }

      for (const pmt of this.invoice.payments) {
        if (pmt.status === constants.PAYMENT_STATUS_ADMIN_REVIEW) {
          return false
        }
      }
      return true
    }
  },
  watch: {

  },

  methods: {
    validatePaymentForm() {
      this.messages = []
      this.errors = []
      if(this.$refs.paymentForm.validate()) {
        this.createPayment()
      }
    },

    fetchInvoice() {
      api
          .fetchInvoice(this.userId, this.invoiceId)
          .then(response => {
            this.invoice = response.data
          })
          .catch(err => {
            this.handleError(err)
          })
    },

    createPayment() {
      const invoiceId = this.invoice.id
      api
          .createPayment(this.userId, invoiceId, this.payment)
          .then(response => {
            if (response.status === 201) {
              this.$refs.paymentForm.reset()
              this.fetchInvoice()
              this.messages.push('Payment submitted for admin review. The amount due will be updated once payment is verified.')
            }
          })
          .catch(err => {
            this.handleError(err)
          })
    },

    handleError(err) {
      let errorMessage = api.parseResponseError(err)
      this.errors.push(errorMessage)
    }
  },

  data() {
    return {
      errors: [],
      messages: [],
      warnings: [],
      paymentValid: true,
      invoiceStatusValid: true,
      userId: null,
      invoice: null,
      invoiceStatusMap: constants.getInvoiceStatusMap(),
      paymentStatusMap: constants.getPaymentStatusMap(),
      payment: {},
      paymentMethods: constants.getPaymentMethods(),
      rules: {
        required: [
          v => validation.isRequired(v) || 'Is required'
        ],
        currency: [
          v => validation.isCurrency(v) || 'Must be in currency format'
        ],
      }
    }
  }
}
</script>

<style lang="scss">
td.item-amount, td.item-qty, td.item-price {
  width: 10%;
}
</style>


import moment from "moment";

const PASSWORD_MIN_LENGTH = 8
const MIN_BIRTH_YEAR = 1900

export default {
    isRequired(val) {
        return !!val
    },

    isEmailAddress(val) {
        let pattern = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
        return pattern.test(val)
    },

    isInteger(val) {
        return /^[0-9]+$/.test(val)
    },

    isOptionalInteger(val) {
        return !val || this.isInteger(val)
    },

    isCurrency(val) {
        return /^[0-9]+(\.[0-9]{0,2})?$/.test(val)
    },

    isOptionalCurrency(val) {
        return !val || this.isCurrency(val)
    },

    isLessThan(val, max) {
        return val < max
    },

    isLessThanOrEqual(val, max) {
        return val <= max
    },

    isMoreThan(val, min) {
        return val > min
    },

    isMoreThanOrEqual(val, min) {
        return val >= min
    },

    isBeforeCurrentYear(val) {
        return val < new Date().getFullYear()
    },

    isBeforeOrEqualToCurrentYear(val) {
        return val <= new Date().getFullYear()
    },

    isAfterCurrentYear(val) {
        return val > new Date().getFullYear()
    },

    isAfterOrEqualToCurrentYear(val) {
        return val >= new Date().getFullYear()
    },

    isDate(val) {
        return moment(val, 'YYYY-MM-DD',true).isValid()
    },

    isUnique(val, list) {
        if (!Array.isArray(list) || list.length === 0) {
            return false
        }

        let occurs = 0
        const vLow = val.toLowerCase()
        list.forEach(el => {
            if (el !== undefined && el.toLowerCase() === vLow) {
                occurs++
            }
        })

        return occurs === 1
    }
}